<template>
  <div class="px-10 pt-5 mainContainer custom-scroll">
    <div class="d-flex upperDiv">
      <v-row align="center" height="100%">
        <v-col class="d-flex" cols="5">
          <v-row>
            <v-col cols="9" class="d-flex align-center">
              <v-btn fab text small color="grey darken-2" @click="calendarPrev">
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title
                dense
                class="px-5 pt-1 currentCalendarDate"
                v-if="$refs.calendar"
              >
                {{ getCalendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab text small color="grey darken-2" @click="calendarNext">
                <v-icon large> mdi-chevron-right </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <!-- deixa flecha mexer -->
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-right d-flex align-center">
          <v-select
            class="rounded-lg hasBlackIcon user-select"
            prepend-inner-icon="la-user"
            append-icon="la-angle-down"
            hide-details
            return-object
            multiple
            chips
            item-text="name"
            v-model="viewPeopleModel"
            :items="viewPeople"
            dense
            color="black"
            style="background-color: white"
            depressed
            outlined
            height="40"
            no-data-text="Nenhuma informação disponível"
            :disabled="!userHasPermission('view_others_attendences')"
          >
          </v-select>
          <v-select
            no-data-text="Nenhuma informação disponível"
            placeholder="Intervalo"
            outlined
            dense
            hide-details
            width="100"
            class="mx-3 rounded-lg elevation-0 hasBlackIcon"
            v-model="type"
            style="background-color: white"
            :items="Object.keys(types)"
            append-icon="la-angle-down"
          ></v-select>
          <v-btn
            v-if="userHasPermission('change_schedule')"
            class="rounded-lg"
            color="#177DFF"
            depressed
            dark
            height="40"
            @click="dialogButtonClick"
            >Novo compromisso</v-btn
          >
          <v-btn
            v-else
            class="rounded-lg disabled-button"
            color="#177DFF"
            depressed
            dark
            height="40"
            @click="dialogButtonClick"
            >Novo compromisso</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div class="pt-5 lowerDiv">
      <v-row class="lowerRow">
        <v-col cols="9" class="lowerRowCol pb-0">
          <v-sheet class="calendarSheet">
            <v-calendar
              interval-height="100"
              class="calendarClass"
              :first-day-of-week="2"
              locale="pt-br"
              :masks="{ title: 'MMM YYYY' }"
              ref="calendar"
              :type="types[type]"
              v-model="value"
              :events="eventsFiltered"
              :event-overlap-threshold="30"
              :event-color="getEventColor"
              :event-ripple="false"
              @click:date="viewDay"
              @click:event="showEvent"
              @mousedown:event="startDrag"
              @mousedown:time="startTime"
              @mousemove:time="mouseMove"
              @mouseup:time="endDrag"
              @mouseleave.native="cancelDrag"
              @click:time="newEventDayWeek"
            >
              <!-- config header day -->
              <template v-slot:day-label-header="event">
                <v-btn fab text color="grey darken-2" @click="viewDay(event)">
                  <span class="textNumberDay">{{ event.day }}</span>
                </v-btn>
                <p class="textWeekday">{{ getWeekdayName(event.weekday) }}</p>
              </template>

              <template
                v-slot:event="{
                  event,
                  timed,
                  eventSummary,
                  timeSummary,
                  eventParsed,
                }"
              >
                <template v-if="timed">
                  <div class="v-event-draggable"></div>
                  <template v-if="type === 'Dia'">
                    <template v-if="getEventHeight(eventParsed) < 50">
                      <v-row class="pl-2">
                        <v-col class="d-flex">
                          <span>{{ event.name }}</span>
                          <v-spacer></v-spacer>
                          <div class="pr-2" v-html="timeSummary()"></div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else-if="getEventHeight(eventParsed) < 60">
                      <v-col class="d-flex">
                        <div class="pl-2">{{ event.name }}</div>
                        <v-spacer></v-spacer>
                        <div class="pr-2" v-html="timeSummary()"></div>
                      </v-col>
                    </template>
                    <row v-else class="ma-0 pa-0">
                      <v-col class="d-flex align-center">
                        <span>
                          {{ event.name }}
                        </span>
                        <v-spacer> </v-spacer>
                        <span class="pr-4">{{ timeSummary() }}</span>
                        <UserPhoto
                          size="30"
                          width="30"
                          height="30"
                          :user="getUser(event.userId)"
                        />
                      </v-col>
                    </row>
                  </template>
                  <template v-else-if="type === 'Semana'">
                    <template v-if="getEventHeight(eventParsed) < 105">
                      <component class="pl-2" :is="{ render: eventSummary }" />
                    </template>
                    <template v-else-if="getEventHeight(eventParsed) < 150">
                      <div class="pt-2 pl-2">
                        <div>
                          <span class="textInEvent">{{ event.name }} </span>
                        </div>
                        <div v-html="timeSummary()"></div>
                      </div>
                      <div class="pl-2">
                         <UserPhoto
                          size="30"
                          width="30"
                          height="30"
                          :user="getUser(event.userId)"
                        />
                      </div>
                    </template>
                    <div v-else class="ma-0 pa-3 d-flex flex-column eventDiv">
                      <div>
                        <span class="textInEvent">{{ event.name }} </span>
                      </div>
                      <div>
                        <div class="pt-3">
                           <UserPhoto
                            size="30"
                            width="30"
                            height="30"
                            :user="getUser(event.userId)"
                          />
                        </div>
                        <span class="textInEvent">
                          <div v-html="timeSummary()" class="pt-5"></div>
                        </span>
                      </div>
                    </div>
                  </template>
                  <component
                    v-else-if="type === 'Mês'"
                    :is="{ render: eventSummary }"
                  />
                  <div
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  ></div>
                </template>
                <v-row v-else class="px-3">
                  <v-col class="d-flex px-3">
                    <component :is="{ render: eventSummary }" />
                  </v-col>
                  <v-spacer></v-spacer>

                  <v-col class="text-right">
                    <div v-html="timeSummary()"></div>
                  </v-col>
                </v-row>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
        <v-col cols="3" class="lowerRowCol pb-0">
          <v-sheet class="cardSheet borderAtvsData">
            <v-card style="height: 100%" class="borderAtvsData">
              <v-card-text style="height: 100%">
                <div class="mx-2 mb-0 containerAtvsData">
                  <v-row class="rowAtvs">
                    <v-col cols="12">
                      <div v-for="(event, i) in attendences" :key="i">
                        <div
                          v-for="(schedule, y) in schedulesFiltered(event)"
                          :key="y"
                        >
                          <v-row
                            @click="eventSumClicked({ event, schedule })"
                            align="center"
                            :class="{
                              'pb-3': true,
                              'd-block': $vuetify.breakpoint.mdAndDown,
                            }"
                            style="cursor: pointer"
                          >
                            <v-col
                              :cols="$vuetify.breakpoint.mdAndDown ? 12 : 8"
                            >
                              <div class="attendance-item pt-0 pb-0" relative>
                                <LineBorder
                                  :color="
                                    getAttendenceStatus(schedule.statusId).color
                                  "
                                  left
                                  height="100%"
                                />
                                <span style="font-weight: 600; color: black">{{
                                  event.client.name
                                }}</span>
                                <br />
                                <span
                                  :style="{
                                    color: getAttendenceStatus(
                                      schedule.statusId
                                    ).color,
                                  }"
                                  class="text-caption spamEventsSum"
                                  >{{
                                    getAttendenceStatus(schedule.statusId).name
                                  }}</span
                                >
                                <br />
                                <span
                                  class="spamEventTime"
                                  style="
                                    font-weight: 600;
                                    color: black;
                                    font-size: 12px;
                                  "
                                  >{{ convertDate(schedule.startTime) }}</span
                                >
                              </div>
                            </v-col>
                            <v-col
                              :class="{
                                'text-right': !$vuetify.breakpoint.mdAndDown,
                                'align-center': $vuetify.breakpoint.mdAndDown,
                                'mt-0': true,
                              }"
                              :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4"
                            >
                              <UserPhoto
                                size="50"
                                width="50"
                                height="50"
                                :user="schedule.user"
                              />
                            </v-col>
                          </v-row>
                          <v-divider
                            v-if="
                              !(
                                i == attendences.length - 1 &&
                                y == event.schedules.length - 1
                              )
                            "
                            class="pb-3"
                          ></v-divider>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="pb-3"></v-divider>
                  <v-row class="rowData">
                    <v-date-picker
                      class="pt-3 sideDatePicker"
                      locale="pt-br"
                      no-title
                      full-width
                      color="blue"
                      v-model="picker"
                      @click:date="dataPickerClickDate"
                      :weekday-format="getWeekdayNameDataPicker"
                      first-day-of-week="1"
                    ></v-date-picker>
                    <div></div>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
      <CreateEditEvent
        v-if="modelDialog"
        @close="closeDialog"
        @createEvent="editEventFunction"
        @deleteEventEmit="deleteEventFunction"
        @editEvent="editEventFunction"
        :selectedEvent="selectedEventComputed"
        :selectedSchedule="findSelectedSchedule"
        :newEvent="newEvent"
        v-model="modelDialog"
        @click.stop
      ></CreateEditEvent>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import UserPhoto from '../../../components/utils/UserPhoto.vue';
import CreateEditEvent from './../modals/CreateEditEvent';

import { createNamespacedHelpers } from 'vuex';

const nameSpacedAttendences = createNamespacedHelpers('attendences');

const {
  mapState: mapStateConfig,
  mapGetters: mapGettersConfig,
  mapActions: mapActionsConfig,
} = createNamespacedHelpers('config');

const {
  mapState: mapStateCalendar,
  mapGetters: mapGettersCalendar,
  mapActions: mapActionsCalendar,
} = createNamespacedHelpers('calendar');

const attendenceStore = {
  mapState: nameSpacedAttendences.mapState,
  mapActions: nameSpacedAttendences.mapActions,
  mapGetters: nameSpacedAttendences.mapGetters,
};

export default {
  components: {
    CreateEditEvent,
    UserPhoto,
  },
  data() {
    return {
      //dialogVars
      selectedEvent: null,
      selectedElement: null,
      selectedSchedule: null,
      modelDialog: false,
      newEvent: null,

      //selectModels
      viewPeopleModel: ['Todas as pessoas'],

      //calendarVars
      events: [],
      type: 'Semana',
      types: { Dia: 'day', Semana: 'week', Mês: 'month' },
      value: '',
      picker: new Date().toISOString().substr(0, 10),
      mode: 'column',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      names: [
        'Meeting',
        'Holiday',
        'PTO',
        'Travel',
        'Event',
        'Birthday',
        'Conference',
        'Party',
      ],

      //dragEvent
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      dragTime: null,
      isDragging: false,

      typesAttendence: {
        1: '#ffa290',
        2: '#5addbe',
        3: '#31cfff',
        4: '#f6b23f',
        5: '#f54ee3',
        6: '#39ece8',
        7: '#177dff',
        8: '#50f292',
        9: '#9d6efc',
      },
    };
  },
  computed: {
    ...mapStateConfig(['userRoles', 'users']),
    ...mapGettersConfig(['getUser']),
    ...attendenceStore.mapState(['attendences', 'statuses']),
    ...attendenceStore.mapGetters([
      'getAttendence',
      'getAttendenceStatus',
      'getStatusByName',
      'getStatusIdByName',
      'getStatusByPlace',
    ]),
    eventsFiltered() {
      if (!this.viewPeopleModel.includes('Todas as pessoas')) {
        return this.events.filter((el) =>
          this.viewPeopleModel.find((i) => i.id == el.userId)
        );
      }
      return this.events;
    },
    viewPeople() {
      return ['Todas as pessoas', ...this.users];
    },
    attendencesComputed() {
      return JSON.parse(JSON.stringify(this.attendences));
    },
    selectedEventComputed() {
      return JSON.parse(JSON.stringify(this.selectedEvent));
    },
    getCalendarTitle() {
      const l = this.$refs.calendar.title.split(' ');
      return this.$refs.calendar.title;
    },
    findSelectedSchedule() {
      return this.attendences.find((el) => el.id == this.selectedSchedule);
    },
  },
  watch: {
    '$route.name'(newVal) {
      if (newVal == 'agenda') {
        this.$refs.calendar.scrollToTime({ hour: 11, minute: 0 });
      }
    },
    '$route.params.openAttendence'(newVal, oldVal) {
      if (this.$route.name == 'agenda') {
        this.modelDialog = true;
        this.selectedSchedule = this.$route.params.openAttendence;
      }
    },
    attendences(newVal) {
      this.events = [];
      let array = newVal;
      // if (this.viewPeopleModel) {
      //   if (!(this.viewPeopleModel == "Todas as pessoas")) {
      //   alert("SADASDAS")

      //     array = array.filter(el => el.user.role.id == this.viewPeopleModel.id)
      //   }
      // }
      array.forEach((el) => {
        if (el.schedules) {
          el.schedules.forEach((schedule) => {
            const evt = {
              name: el.client.name,
              start: new Date(schedule.startTime),
              end: new Date(schedule.endTime),
              color: this.getAttendenceStatus(schedule.statusId).color,
              timed: true,
              id: el.id,
              scheduleId: schedule.id,
              statusId: schedule.statusId,
              description: schedule.description,
              userId: schedule.userId,
            };
            this.events.push(evt);
          });
        }
      });
    },
  },
  methods: {
    ...attendenceStore.mapActions([
      'editAttendence',
      'createAttendenceHistoric',
      'createAttendenceSchedule',
      'editAttendenceSchedule',
      'deleteAttendenceSchedule',
      'addAttendenceResponsible',
    ]),
    eventSumClicked(ev) {
      const attendence = ev.event;
      const schedule = ev.schedule;
      this.selectedEvent = {
        name: schedule.user.name,
        description: schedule.description,
        end: schedule.endTime,
        id: attendence.id,
        scheduleId: schedule.id,
        start: schedule.startTime,
        statusId: schedule.statusId,
        userId: schedule.userId,
      };
      this.modelDialog = true;
    },
    schedulesFiltered(attendence) {
      let array = attendence.schedules;
      if (!this.viewPeopleModel.includes('Todas as pessoas')) {
        array = array.filter((el) =>
          this.viewPeopleModel.find((i) => i.id == el.userId)
        );
      }
      if (this.type == 'Dia') {
        array = array.filter((el) => this.isToday(new Date(el.startTime)));
      } else {
        array = array.filter((el) => {
          const now = moment();
          const input = moment(el.startTime);
          return (
            now.isoWeek() == input.isoWeek() &&
            !moment(input).isBefore(moment(), 'day')
          );
        });
      }
      return array;
    },
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    convertDate(evt) {
      return this.datetimeBr(evt);
    },
    async editEventFunction(event) {
      const scheduleArray = this.getAttendence(event.id).schedules;
      const existingSchedule = scheduleArray.find(
        (el) => el.statusId == event.statusId
      );
      let schedule = {};
      if (existingSchedule) {
        schedule = await this.editAttendenceSchedule({
          schedule: {
            id: existingSchedule.id,
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      } else {
        schedule = await this.createAttendenceSchedule({
          schedule: {
            attendenceId: event.id,
            statusId: event.statusId,
            startTime: event.startTime,
            endTime: event.endTime,
            description: event.description,
            userId: event.user.id,
          },
        });
      }
      const choosenAttendence = this.getAttendence(event.id);
      choosenAttendence.oldDescription = this.statuses.find(
        (el) =>
          el.id ==
          this.attendences.find((el) => el.id == choosenAttendence.id).statusId
      ).name;
      const newDescription = this.statuses.find(
        (el) => el.id == choosenAttendence.statusId
      ).name;
      const contactStatus = this.getStatusByName('Contato');
      if (
        choosenAttendence.statusId == contactStatus.id &&
        schedule.statusId == this.getStatusIdByName('Medição')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          contactStatus.place + 1
        ).id;
      } else if (
        choosenAttendence.statusId == this.getStatusIdByName('Aprovado') &&
        schedule.statusId == this.getStatusIdByName('Conferência de medidas')
      ) {
        choosenAttendence.statusId = this.getStatusByPlace(
          this.getStatusByName('Aprovado').place + 1
        ).id;
      }
      await this.editAttendence({ attendence: choosenAttendence });
      await this.createAttendenceHistoric({
        historic: {
          statusId: choosenAttendence.statusId,
          attendenceId: choosenAttendence.id,
          description: `Atendimento foi de ${choosenAttendence.oldDescription} para ${newDescription}`,
          isActive: true,
          userId: window.localStorage.getItem('userId'),
        },
        loggedUser: this.getUser(window.localStorage.getItem('userId')),
      });
      if (
        !choosenAttendence.responsibles.find((el) => el.id == event.user.id)
      ) {
        await this.addAttendenceResponsible({
          responsible: { attendenceId: event.id, userId: event.user.id },
        });
      }
      this.modelDialog = false;
    },
    async deleteEventFunction(evt) {
      await this.deleteAttendenceSchedule({
        scheduleId: evt.scheduleId,
        attendenceId: evt.id,
      });
      this.modelDialog = false;
    },
    getEventHeight(evt) {
      return (
        evt.end.hour * 60 +
        evt.end.minute -
        (evt.start.hour * 60 + evt.start.minute)
      );
    },
    dataPickerClickDate(date) {
      this.value = date;
    },
    getWeekdayNameDataPicker(date) {
      let i = new Date(date).getDay(date);
      if (this.$vuetify.breakpoint.mdAndDown) {
        var days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
      } else {
        var days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
      }
      return days[i];
    },
    getWeekdayName(number) {
      var days = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
      return days[number];
    },
    closeDialog() {
      this.modelDialog = false;
      this.selectedEvent = null;
      this.selectedElement = null;
      this.selectedOpen = null;
      this.newEvent = null;
      this.selectedSchedule = null;
    },
    newEventDayWeek(event) {
      this.modelDialog = true;
      delete event.week;
      this.newEvent = event;
    },
    dialogButtonClick() {
      this.modelDialog = true;
      this.selectedEvent = null;
      this.selectedElement = null;
    },

    // handle function events

    showEvent({ nativeEvent, event }) {
      if (this.isDragging) {
        const open = () => {
          this.selectedEvent = JSON.parse(JSON.stringify(event));
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.modelDialog = true))
          );
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }
      }

      nativeEvent.stopPropagation();
    },
    viewDay({ date }) {
      this.value = date;
      this.type = 'Dia';
    },
    startDrag({ event, timed }) {
      this.isDragging = true;
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      }
      // else {
      //   this.createStart = this.roundTime(mouse);
      //   this.createEvent = {
      //     name: `Event #${this.events.length}`,
      //     color: '#FFFF',
      //     start: this.createStart,
      //     end: this.createStart,
      //     timed: true,
      //   };
      //   this.events.push(this.createEvent);
      // }
    },
    extendBottom(event) {
      this.isDragging = false;
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.isDragging = false;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      let schedule = {};
      if (this.createEvent && this.createEvent.id) {
        schedule = {
          id: this.createEvent.scheduleId,
          attendenceId: this.createEvent.id,
          statusId: this.createEvent.statusId,
          startTime: this.createEvent.start,
          endTime: this.createEvent.end,
          user: this.getUser(this.createEvent.userId),
        };
        this.editAttendenceSchedule({ schedule });
      } else if (this.dragEvent && !this.isDragging) {
        schedule = {
          id: this.dragEvent.scheduleId,
          attendenceId: this.dragEvent.id,
          statusId: this.dragEvent.statusId,
          startTime: this.dragEvent.start,
          endTime: this.dragEvent.end,
          user: this.getUser(this.dragEvent.userId),
        };
        this.editAttendenceSchedule({ schedule });
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 5; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    calendarPrev() {
      this.$refs.calendar.prev();
    },
    calendarNext() {
      this.$refs.calendar.next();
      // console.log(this.$refs.calendar);
      // if (this.type === 'Semana') {
      //   const dateStart = this.$refs.calendar.lastStart;
      //   let newDateStart = new Date(dateStart.date);
      //   newDateStart = new Date(newDateStart.setDate(newDateStart.getDate() + 3));
      //   const dateFormattedStart = newDateStart.toISOString().split('T')[0];
      //   newDateStart = {
      //     date: dateFormattedStart,
      //     day: newDateStart.getDate(),
      //     month: newDateStart.getMonth(),
      //     weekday: newDateStart.getDay(),
      //     year: newDateStart.getFullYear(),
      //     future: true,
      //     hasDay: true,
      //     hasTime: false,
      //     hour: 0,
      //     minute: 0,
      //     past: false,
      //     present: false,
      //     time: '',
      //   };

      //   const dateEnd = this.$refs.calendar.lastEnd;
      //   let newDateEnd = new Date(dateEnd.date);
      //   newDateEnd = new Date(newDateEnd.setDate(newDateEnd.getDate() + 3));
      //   const dateFormattedEnd = newDateEnd.toISOString().split('T')[0];
      //   newDateEnd = {
      //     date: dateFormattedEnd,
      //     day: newDateEnd.getDate(),
      //     month: newDateEnd.getMonth(),
      //     weekday: newDateEnd.getDay(),
      //     year: newDateEnd.getFullYear(),
      //     future: true,
      //     hasDay: true,
      //     hasTime: false,
      //     hour: 0,
      //     minute: 0,
      //     past: false,
      //     present: false,
      //     time: '',
      //   };

      //   this.$refs.calendar.lastStart = newDateStart;
      //   this.$refs.calendar.lastEnd = newDateEnd;
      //   this.$refs.calendar.checkChange();
      // }
    },
    getEvents() {
      /* this.fixIntervalTime(); */
    },
    fixIntervalTime() {
      const all = document.getElementsByClassName(
        'v-calendar-daily__interval-text'
      );
      for (let i = 1; i < 24; i++) {
        all[i].innerHTML = ('0' + i).slice(-2) + ':' + '00';
      }
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime({ hour: 11, minute: 0 });
    this.$refs.calendar.checkChange();
    this.events = [];
    if (this.$route.params.openAttendence) {
      this.modelDialog = true;
      this.selectedSchedule = this.$route.params.openAttendence;
    }
    JSON.parse(JSON.stringify(this.attendences)).forEach((el) => {
      if (el.schedules) {
        el.schedules.forEach((schedule) => {
          const evt = {
            name: el.client.name,
            start: new Date(schedule.startTime),
            end: new Date(schedule.endTime),
            color: this.getAttendenceStatus(schedule.statusId).color,
            timed: true,
            id: el.id,
            scheduleId: schedule.id,
            statusId: schedule.statusId,
            description: schedule.description,
            userId: schedule.userId,
          };
          this.events.push(evt);
        });
      }
    });
  },
};
</script>

<style>
.mainContainer .user-select {
  max-width: 300px;
}

.mainContainer .v-select__selections {
  flex-wrap: initial;
}

.v-select__selection.v-select__selection--comma {
  color: black;
}

.v-text-field fieldset,
.v-text-field .v-input__control {
  border-color: #e8e8e8 !important;
}

.text-caption.spamEventsSum {
  font-family: 'Poppins', sans-serif !important;
}

.spamEventTime {
  font-weight: 500 !important;
}

.eventDiv {
  height: 100%;
  justify-content: space-between;
}

.borderAtvsData {
  border-radius: 20px !important;
}

.currentCalendarDate {
  font-family: 'Poppins', sans-serif;
  font-size: 26px;
  font-weight: 550;
}

.calendarClass {
  border-radius: 20px !important;
}

.textNumberDay {
  margin-bottom: 0px !important;
  font-size: 35px;
}

.textWeekday {
  margin-top: -10px;
  font-weight: 500;
}

/* name weekday */
.v-calendar-daily_head-weekday {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  align-self: end !important;
}

.v-calendar-daily_head-day.v-present {
  color: #2196f3 !important;
}

div.v-calendar-daily_head-day.v-present
  > div.v-calendar-daily_head-day-label
  > button
  > span
  > span {
  color: #2196f3 !important;
}

div.v-calendar-daily_head-day.v-past
  > div.v-calendar-daily_head-day-label
  > button
  > span
  > span {
  color: #b0b0b0 !important;
}

div.v-calendar-daily_head-day.v-future
  > div.v-calendar-daily_head-day-label
  > button
  > span
  > span {
  color: black !important;
}

.v-calendar-daily__day-interval:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}

.calendarClass .v-calendar-monthly {
  border-radius: 20px;
}

.calendarSheet .v-calendar-weekly__head-weekday:last-child {
  border-top-right-radius: 20px;
}

.calendarSheet .v-calendar-weekly__head-weekday:first-child {
  border-top-left-radius: 20px;
}

.v-event-timed {
  border-radius: 0px 8px 8px 0px !important;
}

.v-menu__content.theme--light.menuable__content__active {
  border: solid 1px #c9c9c9;
  box-shadow: none !important;
}

.textInEvent {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  display: block;
}

.rowAtvs {
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: scroll;
}

.rowData {
  flex: 0 1 40px;
}

.rowAtvs::-webkit-scrollbar {
  display: none;
}

.containerAtvsData {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.lowerRowCol {
  box-sizing: border-box;
  height: 100%;
}

.lowerRow {
  box-sizing: border-box;
  height: 100%;
  max-height: calc(100vh - 127px);
}

.calendarSheet {
  height: 100%;
  border-radius: 20px !important;
  box-sizing: border-box;
}

.cardSheet {
  height: 100%;
  /* border: #e0e0e0 1px solid !important; */
  box-sizing: border-box;
}

.mainContainer {
  height: 100vh;
  overflow: auto !important;
  min-height: 650px;
  padding-bottom: 10px !important;
  box-sizing: border-box;
}

.upperDiv {
  box-sizing: border-box;
}

.lowerDiv {
  box-sizing: border-box;
  height: 100%;
  min-height: 600px;
  padding-bottom: 10px !important;
  max-height: calc(100vh - 130px);
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}

.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: '';
}

.v-event-drag-bottom:hover::after {
  display: block;
}

.attendance-item {
  padding-left: 20px;
}

.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.v-calendar-daily__scroll-area::-webkit-scrollbar {
  display: none;
}

.v-calendar-daily__scroll-area::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.v-calendar-daily__head {
  margin-right: 0px !important;
}

.v-calendar-daily_head-weekday {
  display: none !important;
}

.v-date-picker-table.v-date-picker-table--date {
  padding: 0px 0px 10px 0px;
}

/* ajusta o tamanho datepicker */
.v-date-picker-table {
  min-height: 200px;
  height: auto;
}

.sideDatePicker .v-date-picker-header {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding-right: 4px !important;
}

.sideDatePicker .v-date-picker-header__value {
  grid-column: 1;
  justify-self: left;
}

.sideDatePicker .v-date-picker-header__value button {
  padding: 0;
}

.sideDatePicker .v-btn.v-btn--icon.v-btn--round:nth-child(1) {
  grid-column: 2;
  grid-row: 1;
}

.sideDatePicker .v-btn.v-btn--icon.v-btn--round:nth-child(2) {
  grid-column: 3;
}

.sideDatePicker .v-icon {
  color: #cbcbcb !important;
}

.sideDatePicker .v-date-picker-header__value button {
  text-transform: capitalize;
}

.sideDatePicker .v-date-picker-table th,
.theme--light.v-date-picker-table .v-date-picker-table--date__week {
  color: black;
  font-weight: 600 !important;
}

.vdatetime-input {
  padding-left: 5px;
}

.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: #177dff;
}

.vdatetime-month-picker__item--selected,
.vdatetime-year-picker__item--selected,
.vdatetime-time-picker__item--selected {
  color: #177dff !important;
}

.vdatetime-popup__actions__button.vdatetime-popup__actions__button {
  color: #177dff !important;
}

.hasBlackIcon .v-icon {
  color: black !important;
}

.calendarClass .v-calendar-daily__interval-text {
  font-weight: 500;
  font-size: 12px;
  top: -8px;
}
</style>
